import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import MaterialDashboard from "./material-dashboard";
import Swal from "sweetalert2";
window.Swal = Swal;

const showMessage = (msg = "", type = "success") => {
    const toast = window.Swal.mixin({ toast: true, position: "top-end", showConfirmButton: false, timer: 3000 });
    toast.fire({ icon: type, title: msg, padding: "10px 20px" });
};

const appInstance = createApp(App);

appInstance.config.globalProperties.$showMessage = showMessage;
appInstance.use(router);
appInstance.use(MaterialDashboard);
appInstance.mount("#app");
